import React, {Component} from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

class Index extends Component {
    render() {
        return (
            <Layout>
              <SEO title="Home" />
              <h1>Welcome</h1>
            </Layout>
        )
    }
}

export default Index
